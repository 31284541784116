<template>
  <div>
    <header id="header" class="fixed-top border-bottom bg-main shadow-sm">
      <div class="container-fluid shadow-sm">
        <nav class="navbar navbar-expand-md navbar-dark px-0 pr-sm-3">
          <a @click="back" class="btn p-0 mr-md-4 text-light h5 mb-0">
            <i class="fas fa-arrow-left"></i>
          </a>
          <span
            class="
              navbar-brand
              bg-transparent
              rounded
              p-0
              text-center
              mr-0 mr-md-5
            "
          >
            <span class="h6 font-weight-bold text-truncate">Produk Info</span>
          </span>
          <div class="text-nowrap order-md-last">
            <a
              id="shareBtn"
              class="btn px-0 d-inline-block mr-3"
              @click="shareNew"
            >
              <i class="fas fa-share-alt text-light"></i>
            </a>
            <router-link
              :to="'/jual/produk/edit/' + state.id"
              class="btn px-0 d-inline-block"
            >
              <i class="fas fa-pencil-alt text-light"></i>
            </router-link>
          </div>
        </nav>
      </div>
      <!-- .container -->
    </header>

    <div class="container-fluid mt-header" v-if="state.finish">
      <div class="row" id="body-sidemenu">
        <!-- MAIN -->
        <div id="main-content" class="col pb-5 with-fixed-sidebar">
          <div
            class="jumbotron jumbotron-fluid mb-0 shadow-sm no-side-margin"
            :style="
              'background-image: url(' + state.url + state.data.photo + ');'
            "
          >
            <div class="container text-center">
              <p class="h5 my-2 text-white">{{ state.data.name }}</p>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <p class="mb-1 small text-muted">Harga Modal</p>
              <p class="mb-0 text-main currency">
                Rp. {{ changeMoney(state.data.price) }}
              </p>
            </div>
            <!-- .col -->
            <div class="col-6 text-right text-md-left">
              <p class="mb-1 small text-muted">Diskon</p>
              <p class="mb-0">-</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-3">
            <div class="col-12">
              <p class="mb-1 small text-muted">Deskripsi</p>
              <p class="mb-0">{{ state.data.desc }}</p>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->

          <div class="row mt-3">
            <div class="col-12 px-0">
              <ul class="list-group rounded-0 mt-3">
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <router-link
                    :to="'/jual/produk/pesanan/' + state.id"
                    class="py-2 pl-3 text-uppercase stretched-link"
                    >Pesanan Aktif</router-link
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    href="#"
                    class="
                      py-2
                      pl-3
                      text-uppercase
                      stretched-link
                      text-secondary
                      disabled
                    "
                    >Stok Bahan</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
                <li
                  class="
                    list-group-item
                    d-flex
                    justify-content-between
                    align-items-center
                    border-left-0 border-right-0
                    p-0
                  "
                >
                  <a
                    href="#"
                    class="
                      py-2
                      pl-3
                      text-uppercase
                      stretched-link
                      text-secondary
                      disabled
                    "
                    >Penjualan</a
                  >
                  <i class="fa fa-caret-right mr-3"></i>
                </li>
              </ul>
            </div>
            <!-- .col -->
          </div>
          <!-- .row -->
        </div>
        <!-- main-content -->
      </div>
      <!-- .row -->
    </div>
    <!-- .container -->
  </div>
</template>
<script>
import { reactive, onMounted } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import Share from "../../../../components/social_media/Share.js";
import { useVueSweetAlert2 } from "../../../../useVueSweetAlert2.js";

export default {
  setup() {
    const route = useRoute();
    const $swal = useVueSweetAlert2();
    const state = reactive({
      id: "",
      data: {},
      url: process.env.VUE_APP_API_URL,
      home: process.env.VUE_APP_HOME_URL,
      finish: false,
    });
    const loadData = async () => {
      await axios.get("/api/seller/produk/" + state.id).then((res) => {
        if (res.data.success) {
          state.data = res.data.data;
        }
        state.finish = true;
      });
    };
    onMounted(() => {
      state.id = route.params.id;
      loadData();
    });
    const changeMoney = (dt) => {
      let cur = Math.ceil(Number(dt));
      let mo = cur.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      let ret = mo.split(".")[0];
      return ret;
    };
    const shareData = async () => {
      if (state.data.photo) {
        fetch(state.url + "/api/seller/getImage?image=" + state.data.photo)
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const file = new File([blob], "image.png");
            var filesArray = [file];
            console.log(file);
            //share
            let dt = {
              title: "Share",
              files: filesArray,
              text: state.data.name + "\n" + "*Rp. " + state.data.price + "*",
              url: process.env.VUE_APP_HOME_URL,
            };

            if (
              navigator.canShare &&
              navigator.canShare({ files: filesArray })
            ) {
              try {
                navigator.share(dt);
              } catch (err) {
                $swal.fire({
                  icon: "danger",
                  title: "Error",
                  text: err,
                });
              }
            } else {
              alert("Your system doesn't support sharing files.");
            }
          });
      }
    };
    const shareNew = () => {
      let dt = {
        title: state.data.name,
        url: process.env.VUE_APP_HOME_URL + "/home/produk/" + state.data.key,
        description: "",
      };
      Share.run("whatsapp", dt);
    };
    const back = () => {
      window.history.back();
    };
    return {
      state,
      changeMoney,
      shareData,
      shareNew,
      back,
    };
  },
};
</script>